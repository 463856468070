import React, { PureComponent } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

// Components
import { EventCardLandscapeImage } from '../images/eventCardLandscapeImage';
import formatPrice from '../utility/formatPrice';
import InfoIcon from '../icons/info-icon';
import DiscountedIcon from '../icons/discounted-icon';
import TimeArrow from '../text/timeArrow';
import { formatTitle } from '../utility/formatTitle';

const Card = styled.div`
  background-color: ${props => props.color};
  box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.2);

  display: flex;
  height: 100%;

  & a {
    text-decoration: none;
    color: inherit;
    width: 100%;

    display: flex;
    flex-direction: column;
    // height: 100%;
    flex: 10 0 auto;
  }
`;

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &:hover img {
    filter: grayscale(100);
    mix-blend-mode: multiply;
  }
`;

const TitleContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  padding: 20px;

  flex: 10 0 auto;

  @media (max-width: 575px) {
    padding: 10px;
  }
`;

const CardTitle = styled.h2`
  font-size: 18px;
  text-transform: uppercase;

  width: calc(100% - 18px);

  color: ${props => props.textColor};

  margin: 0;

  @media (max-width: 575px) {
    font-size: 15px;
  }
`;

const CardDetails = styled.div`
  font-size: 14px;
  line-height: 18px;

  background-color: #fff;
  border: 2px solid ${props => props.color};

  padding: 18px;

  & > p {
    margin: 0;
  }

  @media (max-width: 575px) {
    font-size: 12px;

    padding: 8px;
  }
`;

const CardTimes = styled.div`
  // display: flex;
`;

const CardPrice = styled.div`
  font-size: 16px;

  color: ${props => props.textColor};

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

  padding: 13px 20px 20px;

  line-height: 1;

  @media (max-width: 820px) {
    min-height: 80px;
  }

  @media (max-width: 575px) {
    display: block;

    font-size: 13px;
    padding: 10px;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  width: 180px;

  & > p:last-of-type {
    margin-left: 10px;
  }

  & > p:first-of-type {
    margin-left: 0;
  }

  @media (max-width: 575px) {
    width: auto;
    flex-direction: column;
    align-items: flex-start;

    & > p:last-of-type {
      margin-left: 0;
    }
  }
`;

const FreePriceContainer = styled(PriceContainer)`
  & > p:last-of-type {
    margin-left: 0;
  }
`;

const Price = styled.p`
  margin: 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  :hover {
    filter: invert(100%);
  }
`;

const PartnerEventPrice = styled(Price)`
  cursor: initial;

  :hover {
    filter: unset;
  }
`;

const SoldOutPriceContainer = styled(PriceContainer)`
  width: 233px;
`;

const SoldOutPrice = styled(Price)`
  cursor: initial;

  :hover {
    filter: unset;
  }
`;

const ActivePrice = styled.p`
  margin: 0;
  cursor: pointer;

  :hover {
    filter: invert(100%);
  }

  &:after {
    content: ' ';
    display: block;
    border-bottom: 2px solid ${props => props.color};
    padding-top: 3px;
  }

  @media (max-width: 575px) {
    &:after {
      padding-top: 1px;
      border-bottom-width: 1px;
    }
  }
`;

const FreeActivePrice = styled(ActivePrice)`
  :hover {
    filter: unset;
  }
`;

const AddToCart = styled.div`
  cursor: pointer;

  & a {
    display: flex;
    flex-wrap: wrap;

    flex-direction: row;
    align-items: center;
  }

  :hover {
    filter: invert(100%);
  }

  & svg {
    margin-left: 5px;
  }

  @media (max-width: 575px) {
    align-items: flex-start;
  }
`;

const PartnerEventAddToCart = styled(AddToCart)`
  cursor: initial;

  :hover {
    filter: unset;
  }
`;

class EventCard extends PureComponent {
  constructor(props) {
    super(props);
  }

  //   this.state = {
  //     stockLevel: 1,
  //     stockType: 'finite',
  //     stockCheck: false,
  //   };
  //   this.checkStock = this.checkStock.bind(this);
  // }

  // async checkStock(sku) {
  //   const stockDetails = await this.props.onCheckStock(this.props.sku);

  //   if (stockDetails !== undefined) {
  //     this.setState({
  //       stockLevel: stockDetails.currentInventory.inventory.quantity,
  //       stockType: stockDetails.currentInventory.inventory.type,
  //       stockCheck: true,
  //     });

  //     sessionStorage.setItem(this.props.sku, JSON.stringify(stockDetails));
  //   }
  // }

  // componentDidMount(props) {
  //   let stockData = JSON.parse(sessionStorage.getItem(this.props.sku));
  //   if (stockData === null) {
  //     this.checkStock(this.props.sku);
  //   } else {
  //     this.setState({
  //       stockLevel: stockData.currentInventory.inventory.quantity,
  //       stockType: stockData.currentInventory.inventory.type,
  //       stockCheck: true,
  //     });
  //   }
  // }

  render(props, product) {
    return (
      <Card color={this.props.color}>
        <CardItem>
          <Link to={this.props.link}>
            <EventCardLandscapeImage
              image={this.props.details.image}
              alt={this.props.details.title.text}
            />

            <TitleContainer>
              <CardTitle textColor={this.props.textColor}>
                {formatTitle(this.props.details.title.text)}
              </CardTitle>
              <InfoIcon fill={this.props.textColor} />
            </TitleContainer>

            <CardDetails color={this.props.color}>
              <p>
                ■{' '}
                <time dateTime={this.props.details.date}>
                  {this.props.date}
                </time>{' '}
                {this.props.details.end_date && (
                  <>
                    <TimeArrow> → </TimeArrow>{' '}
                    <time dateTime={this.props.details.end_date}>
                      {this.props.details.end_date}
                    </time>
                  </>
                )}
              </p>
              <CardTimes>
                ●{' '}
                <time dateTime={this.props.details.start_time}>
                  {this.props.details.start_time}
                </time>{' '}
                <TimeArrow> → </TimeArrow>
                <time dateTime={this.props.details.finish_time}>
                  {this.props.details.finish_time}
                </time>
              </CardTimes>

              <p>▲ {this.props.details.location.document.data.title.text}</p>
              <p>
                ☀{' '}
                {this.props.details.tutor.document !== null &&
                  this.props.details.tutor.document.data.name.text}
              </p>
              {this.props.details.patreon_status !== 'Not Applicable' && (
                <>
                  <br />
                  <p>{this.props.details.patreon_status}</p>
                </>
              )}
            </CardDetails>
          </Link>

          {/* {(this.state && this.state.stockLevel >= 1) ||
          this.state.stockType === 'infinite' ? ( */}
          <CardPrice textColor={this.props.textColor}>
            {this.props.details.full_price > 0 &&
              this.props.details.partner_event !== 'True' && (
                <>
                  <PriceContainer>
                    {this.props.activePrice === 'full_price' ? (
                      <ActivePrice
                        color={this.props.textColor}
                        onClick={(price, sku) =>
                          this.props.onClick('full_price', this.props.sku)
                        }
                      >
                        {formatPrice(this.props.details.full_price, 'gbp')}
                      </ActivePrice>
                    ) : (
                      <Price
                        onClick={(price, sku) =>
                          this.props.onClick('full_price', this.props.sku)
                        }
                      >
                        {formatPrice(this.props.details.full_price, 'gbp')}
                      </Price>
                    )}

                    {this.props.details.discounted_price !== null && (
                      <>
                        {this.props.activePrice === 'discounted_price' ? (
                          <ActivePrice
                            color={this.props.textColor}
                            onClick={(price, sku) =>
                              this.props.onClick(
                                'discounted_price',
                                this.props.sku
                              )
                            }
                          >
                            (
                            <DiscountedIcon fill={this.props.textColor} />{' '}
                            {formatPrice(
                              this.props.details.discounted_price,
                              'gbp'
                            )}
                            )
                          </ActivePrice>
                        ) : (
                          <Price
                            onClick={(price, sku) =>
                              this.props.onClick(
                                'discounted_price',
                                this.props.sku
                              )
                            }
                          >
                            (
                            <DiscountedIcon fill={this.props.textColor} />{' '}
                            {formatPrice(
                              this.props.details.discounted_price,
                              'gbp'
                            )}
                            )
                          </Price>
                        )}
                      </>
                    )}
                  </PriceContainer>

                  {this.props.details.eventbrite_link !== undefined && (
                    <>
                      {this.props.details.eventbrite_link.url !== '' && (
                        <AddToCart>
                          <a
                            href={this.props.details.eventbrite_link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Book Here
                          </a>
                        </AddToCart>
                      )}
                    </>
                  )}
                </>
              )}

            {this.props.details.full_price <= 0 && (
              <>
                <FreePriceContainer>
                  <SoldOutPrice>FREE</SoldOutPrice>
                </FreePriceContainer>

                {/* <SoldOutPrice> */}
                {/* Enquire <InfoIcon fill={this.props.textColor} /> */}
                {/* </SoldOutPrice> */}

                {this.props.details.eventbrite_link !== undefined && (
                  <>
                    {this.props.details.eventbrite_link.url !== '' && (
                      <AddToCart>
                        <a
                          href={this.props.details.eventbrite_link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Book Here
                        </a>
                      </AddToCart>
                    )}
                  </>
                )}
              </>
            )}

            {this.props.details.full_price > 0 &&
              this.props.details.partner_event === 'True' && (
                <>
                  <PriceContainer>
                    <PartnerEventPrice
                      onClick={(price, sku) =>
                        this.props.onClick('full_price', this.props.sku)
                      }
                    >
                      {formatPrice(this.props.details.full_price, 'gbp')}
                    </PartnerEventPrice>

                    {this.props.details.discounted_price !== null && (
                      <PartnerEventPrice
                        onClick={(price, sku) =>
                          this.props.onClick('discounted_price', this.props.sku)
                        }
                      >
                        (<DiscountedIcon fill={this.props.textColor} />{' '}
                        {formatPrice(
                          this.props.details.discounted_price,
                          'gbp'
                        )}
                        )
                      </PartnerEventPrice>
                    )}
                  </PriceContainer>

                  {this.props.details.eventbrite_link !== undefined && (
                    <>
                      {this.props.details.eventbrite_link.url !== '' && (
                        <AddToCart>
                          <a
                            href={this.props.details.eventbrite_link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Book Here
                          </a>
                        </AddToCart>
                      )}
                    </>
                  )}

                  {/* <PartnerEventAddToCart>
                      Enquire <InfoIcon fill={this.props.textColor} />
                    </PartnerEventAddToCart> */}
                </>
              )}
          </CardPrice>
          {/* ) : (
            <CardPrice textColor={this.props.textColor}>
              <SoldOutPriceContainer>
                <SoldOutPrice>
                  {formatPrice(this.props.details.full_price, 'gbp')}
                </SoldOutPrice>

                {this.props.details.discounted_price !== null && (
                  <SoldOutPrice>
                    (<DiscountedIcon fill={this.props.textColor} />{' '}
                    {formatPrice(this.props.details.discounted_price, 'gbp')})
                  </SoldOutPrice>
                )}
              </SoldOutPriceContainer>

              <SoldOutPrice>Fully Booked</SoldOutPrice>
            </CardPrice>
          )} */}
        </CardItem>
      </Card>
    );
  }
}

export default EventCard;
