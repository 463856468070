import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  width: 18px;
  height: 18px;
  margin-top: 2px;
`;

const InfoIcon = props => (
  <Icon
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 19"
    fill={props.fill}
  >
    <path d="M9.5.5a9,9,0,1,0,9,9A9,9,0,0,0,9.5.5Zm-1,3.25h2v2h-2Zm4.3,10.35H6.25V12.57H8.61V8.28h-2V6.77h3.85v5.8h2.35Z" />
  </Icon>
);

export default InfoIcon;
