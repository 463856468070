import React from 'react';

export const EventCardLandscapeImage = ({ image, alt }) => (
  <img
    srcSet={image.fluid.srcSetWebp}
    src={image.fluid.srcWebp}
    loading="lazy"
    alt={alt}
  />
);
