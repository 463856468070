import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  width: 16px;
  height: 14px;
  margin-top: 2px;
`;

const DiscountedIcon = props => (
  <Icon
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 14"
    fill={props.fill}
  >
    <path d="M11.6 10.4c-.4.3-1.5 1-3.6 1s-3.2-.6-3.6-1V8.6l3 1.7c.4.2.8.2 1.2 0l3-1.7v1.8zm2.9-5.5L8.6 1.6c-.4-.2-.8-.2-1.2 0L1.5 4.9c-.1.1-.2.1-.3.2-.2.1-.3.3-.3.5v6c0 .3.3.6.6.6s.6-.3.6-.6V7.3l1.1.7v2.6c0 .3.1.5.3.7.5.4 1.8 1.3 4.5 1.3s4-.9 4.5-1.4c.2-.2.3-.4.3-.7V8l1.7-1c.8-.5.8-1.7 0-2.1" />
  </Icon>
);

export default DiscountedIcon;
